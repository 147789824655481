import { defineStore } from 'pinia/dist/pinia';
import axios from 'axios';
import { useToast } from 'vue-toastification';

export const useOrderStore = defineStore('order', {
	state: () => {
		return {
			order: null,
			reasonsOfRejected: null,
			selectValues: null,
			legalForm: null,
			industry: { id: 1, title: 'Металлургия' },
			organisation: null,
			legalName: '',
			inn: '',
			kpp: '',
			ogrn: '',
			headPosition: '',
			headPositionGenitive: '',
			headSurname: '',
			headName: '',
			headLastname: '',
			headSurnameGenitive: '',
			headNameGenitive: '',
			headLastnameGenitive: '',
			agency: '',
			surname: '',
			name: '',
			lastname: '',
			birthday: '',
			passportNumbers: '',
			passportDate: '',
			passportOrganizationName: '',
			passportOrganizationCode: '',
			index: '',
			region: '',
			city: '',
			street: '',
			houseNumber: '',
			buildingNumber: '',
			room: '',
			legalAddress: '',
			postalAddress: '',
			actualAddress: '',
			howToReceiveMail: 'postRU',
			paymentAccount: '',
			bankName: '',
			bankCode: '',
			bankCity: '',
			correspondentAccount: '',
			contactSurname: '',
			contactName: '',
			contactLastname: '',
			contactPhone: '',
			contactEmail: '',
			codeWord: '',
			workersCount: '',
			ogrn_file: null,
			supervisor_file: null,
			fns_file: null,
			procuration_file: null,
		};
	},
	actions: {
		async registerOrder() {
			const payload = new FormData();

			const payment_details = {
				payment_account: this.paymentAccount,
				bank_name: this.bankName,
				bank_identification_code: this.bankCode,
				bank_city: this.bankCity,
				correspondent_account: this.correspondentAccount,
			};
			const contacts = {
				name_contact_person: this.contactName,
				lastname_contact_person: this.contactLastname,
				surname_contact_person: this.contactSurname,
				contacts_person_phone: this.contactPhone,
				contacts_person_email: this.contactEmail,
				code_word: this.codeWord,
				count_company_workers: Number(this.workersCount),
			};
			const addresses = {
				legal_address_and_index: this.legalAddress,
				postal_address: this.postalAddress,
				actual_address: this.actualAddress,
				how_to_receive_mail: this.howToReceiveMail,
			};
			const passport_company_owner = {
				surname: this.surname,
				name: this.name,
				lastname: this.lastname,
				birthday: this.birthday,
				serial_number: Number(this.passportNumbers),
				date_of_issue: this.passportDate,
				get_by: this.passportOrganizationName,
				division_code: this.passportOrganizationCode,
				post_index: this.index,
				region: this.region,
				city: this.city,
				street: this.street,
				house_number: this.houseNumber,
				build_index: this.buildingNumber,
				apartment_number: this.room,
			};
			const entity = {
				entity_type: this.organisation.id,
				legal_name: this.legalName,
				inn_number: Number(this.inn),
				kpp_number: Number(this.kpp),
				ogrn_ogrnip: Number(this.ogrn),
				position_of_the_head: this.headPosition,
				position_of_the_head_genetive: this.headPositionGenitive,
				name_of_the_head: this.headName,
				name_of_the_head_genetive: this.headNameGenitive,
				surname_of_the_head: this.headSurname,
				surname_of_the_head_genetive: this.headSurnameGenitive,
				lastname_of_the_head: this.headLastname,
				lastname_of_the_head_genetive: this.headLastnameGenitive,
				executive_agency: this.agency,
			};
			const right_forms = {
				industry: this.industry.title || this.industry,
				legal_form_ip: this.legalForm.id || 1,
			};

			payload.append('payment_details', JSON.stringify(payment_details));
			payload.append('contacts', JSON.stringify(contacts));
			payload.append('addresses', JSON.stringify(addresses));
			payload.append('passport_company_owner', JSON.stringify(passport_company_owner));
			payload.append('right_forms', JSON.stringify(right_forms));
			payload.append('entity', JSON.stringify(entity));
			payload.append('ogrn_ogrnip', this.ogrn_file);
			payload.append('supervisor_order', this.supervisor_file);
			payload.append('fns_notification', this.fns_file);
			payload.append('procuration', this.procuration_file);

			return await axios
				.post(`/api/register/order`, payload)
				.then(() => {
					return true;
				})
				.catch((e) => {
					useToast().error(e.message);
					console.log(e);
					return false;
				});
		},
		async updateOrder(id) {
			const payload = new FormData();

			const payment_details = {
				payment_account: this.paymentAccount,
				bank_name: this.bankName,
				bank_identification_code: this.bankCode,
				bank_city: this.bankCity,
				correspondent_account: this.correspondentAccount,
			};
			const contacts = {
				name_contact_person: this.contactName,
				lastname_contact_person: this.contactLastname,
				surname_contact_person: this.contactSurname,
				contacts_person_phone: this.contactPhone,
				contacts_person_email: this.contactEmail,
				code_word: this.codeWord,
				count_company_workers: Number(this.workersCount),
			};
			const addresses = {
				legal_address_and_index: this.legalAddress,
				postal_address: this.postalAddress,
				actual_address: this.actualAddress,
				how_to_receive_mail: this.howToReceiveMail,
			};
			const passport_company_owner = {
				surname: this.surname,
				name: this.name,
				lastname: this.lastname,
				birthday: this.birthday,
				serial_number: Number(this.passportNumbers),
				date_of_issue: this.passportDate,
				get_by: this.passportOrganizationName,
				division_code: this.passportOrganizationCode,
				post_index: this.index,
				region: this.region,
				city: this.city,
				street: this.street,
				house_number: this.houseNumber,
				build_index: this.buildingNumber,
				apartment_number: this.room,
			};
			const entity = {
				entity_type: this.organisation.id,
				legal_name: this.legalName,
				inn_number: Number(this.inn),
				kpp_number: Number(this.kpp),
				ogrn_ogrnip: Number(this.ogrn),
				position_of_the_head: this.headPosition,
				position_of_the_head_genetive: this.headPositionGenitive,
				name_of_the_head: this.headName,
				name_of_the_head_genetive: this.headNameGenitive,
				surname_of_the_head: this.headSurname,
				surname_of_the_head_genetive: this.headSurnameGenitive,
				lastname_of_the_head: this.headLastname,
				lastname_of_the_head_genetive: this.headLastnameGenitive,
				executive_agency: this.agency.id,
			};
			const right_forms = {
				industry: this.industry.title || this.industry,
				legal_form_ip: this.legalForm.id || 1,
			};

			payload.append('payment_details', JSON.stringify(payment_details));
			payload.append('contacts', JSON.stringify(contacts));
			payload.append('addresses', JSON.stringify(addresses));
			payload.append('passport_company_owner', JSON.stringify(passport_company_owner));
			payload.append('right_forms', JSON.stringify(right_forms));
			payload.append('entity', JSON.stringify(entity));

			if (!this.ogrn_file.path) {
				payload.append('ogrn_ogrnip', this.ogrn_file);
			}
			if (!this.supervisor_file.path) {
				payload.append('supervisor_order', this.supervisor_file);
			}
			if (!this.fns_file.path) {
				payload.append('fns_notification', this.fns_file);
			}
			if (!this.procuration_file.path) {
				payload.append('procuration', this.procuration_file);
			}

			return await axios
				.post(`/api/register/order/${id}?_method=put&action=editOrder`, payload)
				.then(() => {
					return true;
				})
				.catch((e) => {
					useToast().error(e.message);
					console.log(e);
					return false;
				});
		},
		async getSelectValues() {
			if (!this.selectValues) {
				await axios
					.get('/api/auth/form-data')
					.then((response) => {
						this.selectValues = response.data.data;
						this.legalForm = this.selectValues.client_type[0];
						this.agency = this.selectValues.executive_agency[0];
						this.organisation = this.selectValues.entity_type[0];
					})
					.catch();
			}
		},
		setHowToReceiveMail(howToReceiveMail) {
			this.howToReceiveMail = howToReceiveMail;
		},
		async getOrder(important = false) {
			if (!this.order || (this.order && important)) {
				const response = await axios.get(`/api/register/users-order`);
				this.order = response.data.data;
				if (this.order.status === 'rejected') {
					this.legalForm = response.data.data.right_forms.legal_form_ip;
					this.industry = response.data.data.right_forms.industry;

					this.organisation = response.data.data.entity.entity_type;
					this.legalName = response.data.data.entity.legal_name;
					this.inn = response.data.data.entity.inn_number;
					this.kpp = response.data.data.entity.kpp_number;
					this.ogrn = response.data.data.entity.ogrn_ogrnip;
					this.headPosition = response.data.data.entity.position_of_the_head;
					this.headPositionGenitive = response.data.data.entity.position_of_the_head_genetive;
					this.headSurname = response.data.data.entity.surname_of_the_head;
					this.headName = response.data.data.entity.name_of_the_head;
					this.headLastname = response.data.data.entity.lastname_of_the_head;
					this.headSurnameGenitive = response.data.data.entity.surname_of_the_head_genetive;
					this.headNameGenitive = response.data.data.entity.name_of_the_head_genetive;
					this.headLastnameGenitive = response.data.data.entity.lastname_of_the_head_genetive;
					this.agency = response.data.data.entity.executive_agency;

					this.surname = response.data.data.passport_company_owner.surname;
					this.name = response.data.data.passport_company_owner.name;
					this.lastname = response.data.data.passport_company_owner.lastname;
					this.birthday = response.data.data.passport_company_owner.birthday;
					this.passportNumbers = response.data.data.passport_company_owner.serial_number;
					this.passportDate = response.data.data.passport_company_owner.date_of_issue;
					this.passportOrganizationName = response.data.data.passport_company_owner.get_by;
					this.passportOrganizationCode = response.data.data.passport_company_owner.division_code;
					this.index = response.data.data.passport_company_owner.post_index;
					this.region = response.data.data.passport_company_owner.region;
					this.city = response.data.data.passport_company_owner.city;
					this.street = response.data.data.passport_company_owner.street;
					this.houseNumber = response.data.data.passport_company_owner.house_number;
					this.buildingNumber = response.data.data.passport_company_owner.build_index;
					this.room = response.data.data.passport_company_owner.apartment_number;

					this.legalAddress = response.data.data.addresses.legal_address_and_index;
					this.postalAddress = response.data.data.addresses.postal_address;
					this.actualAddress = response.data.data.addresses.actual_address;
					this.howToReceiveMail = response.data.data.addresses.how_to_receive_mail;

					this.paymentAccount = response.data.data.payment_details.payment_account;
					this.bankName = response.data.data.payment_details.bank_name;
					this.bankCode = response.data.data.payment_details.bank_identification_code;
					this.bankCity = response.data.data.payment_details.bank_city;
					this.correspondentAccount = response.data.data.payment_details.correspondent_account;

					this.contactSurname = response.data.data.contacts.surname_contact_person;
					this.contactName = response.data.data.contacts.name_contact_person;
					this.contactLastname = response.data.data.contacts.lastname_contact_person;
					this.contactPhone = response.data.data.contacts.contacts_person_phone;
					this.contactEmail = response.data.data.contacts.contacts_person_email;
					this.codeWord = response.data.data.contacts.code_word;
					this.workersCount = response.data.data.contacts.count_company_workers;

					this.ogrn_file = response.data.data.documents.ogrn_ogrnip;
					this.supervisor_file = response.data.data.documents.supervisor_order;
					this.fns_file = response.data.data.documents.fns_notification;
					this.procuration_file = response.data.data.documents.procuration;

					this.reasonsOfRejected = response.data.data.reason_of_rejected;
				} else {
					this.reasonsOfRejected = [];
				}
			}
		},
	},
	getters: {
		userRegister: (state) => state.isUserRegister,
		userAuth: (state) => state.isUserAuth,
		tokenAlive: (state) => state.isTokenAlive,
	},
});
