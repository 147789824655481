import { defineStore } from 'pinia/dist/pinia';
import { useAppeals } from '@/stores/appeals';
import Pusher from 'pusher-js';
import axios from 'axios';

export const useUserStore = defineStore('user', {
	state: () => {
		return {
			user: null,
			isUserUpdated: false,
			isAvatarUpdated: false,
			isPasswordUpdated: false,
			isPasswordError: false,
			searchResult: null,
			notifications: [],
			notificationsPagination: null,
			// readNotifications: [],
			hasNewNotifications: false,
			isNew: null,
			organization: null,
			lastAppliedId: null,
			WSConfig: null,
			WSChannel: null,
		};
	},
	actions: {
		async getUserData(important = false) {
			if (!this.user || (this.user && important) || !this.user?.email_verified_at) {
				const response = await axios.get('/api/user');
				this.user = response.data.data.user;
				// if (this.organization) {
				// 	this.organization.status = response.data.data.status;
				// } else {
				// 	this.organization = { status: response.data.data.status };
				// }
				this.organization = response.data.data.organization;
				this.lastAppliedId = response.data.data.accepted_id;

				// this.notifications = response.data.notifications;
				// this.isNew = response.data.is_new
			}
		},
		async getOrgData(id) {
			return await axios
				.get(`/api/organization/${id}`)
				.then((res) => {
					if (res.status < 300) {
						if (this.organization?.status) {
							this.organization = {
								...this.organization,
								...res.data.data,
							};
						} else {
							this.organization = res.data.data;
						}
						return true;
					}
					return false;
				})
				.catch((e) => {
					console.log(e);
					return false;
				});
		},
		async updateUserData(payload) {
			this.isUserUpdated = false;
			await axios.put('/user/', payload).then(() => {
				this.isUserUpdated = true;
			});
		},
		async updateUserAvatar(file) {
			this.isAvatarUpdated = false;
			await axios.post('/api/users/avatar', file).then(() => {
				this.isAvatarUpdated = true;
			});
		},
		async updateUserPassword(payload) {
			this.isPasswordUpdated = false;
			this.isPasswordError = false;
			const response = await axios.put('/user/password', payload);
			if (response.data.message === 'Неверный пароль') {
				this.isPasswordError = true;
			} else {
				this.isPasswordUpdated = true;
			}
		},
		async searchUser(query) {
			const response = await axios.get(`/user/search?search=${query}`);
			this.searchResult = response.data.users;
		},
		async getMyNotifications(page) {
			return await axios
				.get(`/api/notifications?page=${page}`)
				.then((res) => {
					if (res.status < 300) {
						this.notifications.push(...res.data.data);
						this.notificationsPagination = res.data.pagination;
						return true;
					}
					return false;
				})
				.catch((e) => {
					console.log(e);
					return false;
				});
		},
		// async deleteNotification(id) {
		// 	return await axios
		// 		.delete(`/api/notifications/${id}`)
		// 		.then((res) => {
		// 			return res.status < 300;
		// 		})
		// 		.catch((e) => {
		// 			console.log(e);
		// 			return false;
		// 		});
		// },
		followNotifications(user_id) {
			this.WSConfig = new Pusher('0cc32f798fe8825d5a6f', {
				wsHost: 'ws.calldom.ru',
				cluster: 'eu',
				wsPort: 6001,
				forceTLS: false,
				disableStats: true,
				authEndpoint: 'https://api.calldom.ru/api/broadcasting/auth',
				auth: {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				},
			});

			this.WSChannel = this.WSConfig.subscribe(`private-notification.${user_id}`);
			this.WSChannel.bind(`App\\Events\\NotificationEvent`, async (data) => {
				if (data.notification.type === 'completed_request_appeal') {
					useAppeals().appeal.status = 'complete';
				}
				this.notifications.unshift(data.notification);
				this.hasNewNotifications = true;
			});
			this.WSChannel.bind('pusher:subscription_error', (e) => {
				console.log(e);
			});
		},
		async readMyNotification() {
			const idsArr = this.notifications.map((item) => item.id);
			return (
				idsArr.length &&
				(await axios
					.put(`/api/notifications/read`, { id: idsArr })
					.then(async (res) => {
						if (res.status < 300) {
							this.notifications = [];
							await this.getMyNotifications(1).then((res) => {
								if (res) {
									this.hasNewNotifications = false;
								}
							});
							return true;
						}
						return false;
					})
					.catch((e) => {
						console.log(e);
						return false;
					}))
			);
		},
		async readNotification(id) {
			return await axios
				.put(`/api/notifications/${id}`)
				.then((res) => {
					return res.status < 300;
				})
				.catch((e) => {
					console.log(e);
					return false;
				});
		},
		async readUserNotifications() {
			await axios.put('/notifications');
			await this.getUserData();
		},
	},
	getters: {
		userData: (state) => state.user,
		userUpdated: (state) => state.isUserUpdated,
		avatarUpdated: (state) => state.isAvatarUpdated,
		passwordUpdated: (state) => state.isPasswordUpdated,
		passwordError: (state) => state.isPasswordError,
		searchedUsers: (state) => state.searchResult,
		userAvatar: (state) => `${state.user.avatar?.path}`,
		unreadNotifications: (state) => {
			let count = 0;
			state.notifications.forEach((notice) => {
				if (!notice.is_read) {
					count++;
				}
			});
			return count;
		},
	},
});
