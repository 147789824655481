import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import translationRu from '@/i18n/ru.json';
import translationEn from '@/i18n/en.json';
import translationHy from '@/i18n/hy.json';

let lang = localStorage.getItem('i18nextLng');
if (!lang) {
	lang = navigator.language.substring(0, 2);
	localStorage.setItem('i18nextLng', lang);
}

i18next.init({
	lng: lang,
	locale: 'ru',
	escapeValue: true,
	debug: true,
	fallbackLng: 'en',
	warnHtmlInMessage: false,
	resources: {
		ru: translationRu,
		en: translationEn,
		hy: translationHy,
	},
});

export default function (app) {
	app.use(I18NextVue, { i18next });
	return app;
}
