import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia/dist/pinia';
import App from './App.vue';
import { routes } from '@/routes';
import '@/sass/main.scss';
import axios from 'axios';
import { useAuthStore } from '@/stores/auth';
import i18n from './i18n';
import Toast, { POSITION } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import 'vue-select/dist/vue-select.css';
import 'vue-tel-input/dist/vue-tel-input.css';

const toastOptions = {
	position: POSITION.TOP_CENTER,
};

//Axios config
axios.defaults.baseURL = 'https://api.calldom.ru';
axios.defaults.validateStatus = (status) => status < 501;
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

// @ts-ignore
axios.interceptors.response.use((response) => {
	if (response.status === 401) {
		useAuthStore().logout();
	}
	if (response.status === 403) {
		useAuthStore().noAccess();
	}
	if (response.status === 422) {
		return response;
	}
	return response;
});

//Store
const pinia = createPinia();
pinia.use(({ store }) => {
	store.router = markRaw(routes);
});

const app = createApp(App);
app.use(routes);
app.use(Toast, toastOptions);
app.use(pinia);
app.use(i18n);
app.mount('#app');
