import { defineStore } from 'pinia/dist/pinia';
import axios from 'axios';

export const useAuthStore = defineStore('auth', {
	state: () => {
		return {
			isUserRegister: false,
			isUserAuth: false,
			isTokenAlive: false,
			isVerificationStep: false,
			registeredEmail: '',
			languages: [
				{ id: 1, title: 'Русский', abbreviation: 'ru' },
				{ id: 2, title: 'English', abbreviation: 'en' },
				{ id: 3, title: 'հայերեն', abbreviation: 'hy' },
			],
		};
	},
	actions: {
		async registerUser(payload) {
			return await axios
				.post('/api/auth/register-organization', payload)
				.then(async (response) => {
					await localStorage.setItem('token', response.data.data.token);
					axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
					this.registeredEmail = payload.email;
					return true;
				})
				.catch((e) => {
					console.log(e);
					return false;
				});
		},
		async sendVerificationCode() {
			try {
				await axios.post('/api/auth/send-verification-code');
			} catch (e) {
				console.log(e);
			}
		},
		async confirmEmail(payload) {
			try {
				this.isVerificationStep = true;
				const response = await axios.post('/api/auth/verification-email', payload);
				if (!response.data.status) {
					return false;
				} else {
					this.isUserRegister = true;
					this.isUserAuth = true;
					// const userStore = useUserStore()
					// await userStore.getUserData();
					return true;
				}
			} catch (e) {
				console.log(e);
				return false;
			}
		},
		async loginUser(payload) {
			const response = await axios.post('/api/auth/login', payload);
			if (!response.data.status) {
				return false;
			} else {
				await localStorage.setItem('token', response.data.data.token);
				axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
				this.isUserAuth = true;

				// const userStore = useUserStore()
				// await userStore.getUserData();
				return true;
			}
		},
		async sendForgotPassword(payload) {
			try {
				const response = await axios.post('/api/auth/forgot-password', payload);
				if (response.data.errors.email) {
					return 'email';
				}
				if (response.data.data.scalar === 'Please wait before retrying.') {
					return 'wait';
				}
				if (response.data.data.scalar === 'We have emailed your password reset link.') {
					return 'sent';
				}
			} catch (e) {
				console.log(e);
				return 'server error';
			}
		},
		// async checkToken(payload) {
		//     this.isTokenAlive = false
		//     try {
		//         const response = await axios.get('/auth/forgot-check', {
		//             params: payload
		//         })
		//         if (response.status < 300 && response.data[0]) {
		//             this.isTokenAlive = true;
		//         }
		//     } catch (error) {
		//         console.log(error)
		//     }
		// },
		async resetPassword(payload) {
			try {
				const response = await axios.post('/api/auth/reset-password', payload);
				if (response.status < 300) {
					return true;
				}
				return false;
			} catch (error) {
				console.log(error);
				return false;
			}
		},
		logout() {
			localStorage.removeItem('token');
			this.router.push('/login');
		},
		noAccess() {
			if (!this.isVerificationStep) {
				this.router.push('/');
				throw Error('No Access');
			}
		},
	},
	getters: {
		userRegister: (state) => state.isUserRegister,
		userAuth: (state) => state.isUserAuth,
		tokenAlive: (state) => state.isTokenAlive,
	},
});
