import { defineStore } from 'pinia';
import Pusher from 'pusher-js';
import axios from 'axios';
import { useToast } from 'vue-toastification';

export const useAppeals = defineStore('appeals', {
	state: () => {
		return {
			appealList: [],
			appealsPages: 0,
			appeal: {},
			// appealMessages: [],
			WSConfig: null,
			WSChannel: null,
		};
	},
	getters: {},
	actions: {
		async getAppeals(page) {
			return await axios
				.get(`/api/support/appeals?page=${page}`)
				.then((response) => {
					this.appealList = response.data.data;
					this.appealsPages = response.data.pagination.last_page;
					return true;
				})
				.catch((e) => {
					console.log(e);
					return false;
				});
		},
		async createAppeal(text) {
			return await axios
				.post('/api/support/appeals', text)
				.then(async (res) => {
					if (res.status < 300) {
						if (res.data.data.message === 'Appeal success created') {
							// refresh connection
							this.WSChannel = this.WSConfig.unsubscribe(`private-chat.${this.appeal.id}`);
							await this.getAppeal(res.data.data.appeal_id);
							this.followMessages();
						}
						return true;
					}
					return false;
				})
				.catch((e) => {
					useToast().error(e.message);
					console.log(e);
					return false;
				});
		},
		async getAppeal(id) {
			return await axios
				.get(`/api/support/appeals/${id}`)
				.then((response) => {
					this.appeal = response.data.data;
					this.appealMessages = response.data.data.messages;
					return true;
				})
				.catch((e) => {
					console.log(e);
					return false;
				});
		},
		async updateAppeal(id, payload) {
			return await axios
				.put(`/api/support/appeals/${id}`, payload)
				.then(() => {
					return true;
				})
				.catch((e) => {
					console.log(e);
					return false;
				});
		},
		async answerToUser(data) {
			return await axios
				.post('/api/support/appeals/answer-to-user/', data)
				.then(async (res) => {
					return res.status < 300;
				})
				.catch((e) => {
					console.log(e);
					return false;
				});
		},
		followMessages(appealId = this.appeal?.id) {
			this.WSConfig = new Pusher('0cc32f798fe8825d5a6f', {
				wsHost: 'ws.calldom.ru',
				cluster: 'eu',
				wsPort: 6001,
				forceTLS: false,
				disableStats: true,
				authEndpoint: 'https://api.calldom.ru/api/broadcasting/auth',
				auth: {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
				},
			});

			this.WSChannel = this.WSConfig.subscribe(`private-chat.${appealId}`);
			this.WSChannel.bind(`App\\Events\\MessageSent`, async (data) => {
				this.appeal?.messages?.push(data.message);
			});
			this.WSChannel.bind('pusher:subscription_error', (e) => {
				console.log(e);
			});
		},
	},
});
