import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/personal-account/user';
import { useOrderStore } from '@/stores/order';
import i18next from 'i18next';
const routerHistory = createWebHistory();

export const routes = createRouter({
	history: routerHistory,
	routes: [
		{
			path: '/',
			name: 'MainLayout',
			component: () => import('@/layouts/MainLayout'),
			meta: { noAuth: true },
			redirect: { name: 'Main' },
			children: [
				{
					path: '/',
					name: 'Main',
					component: () => import('@/pages/Main/MainPage'),
					meta: { noAuth: true, title: 'main' },
				},
				// {
				// 	path: '/our-services',
				// 	name: 'OurServices',
				// 	component: () => import('@/pages/Main/OurServices'),
				// 	meta: { noAuth: true, title: 'our-services' },
				// },
				{
					path: '/our-prices',
					name: 'OurPrices',
					component: () => import('@/pages/Main/Prices/OurPrices'),
					meta: { noAuth: true, title: 'our-services' },
				},
				{
					path: '/contacts',
					name: 'ContactsPage',
					component: () => import('@/pages/Main/ContactsPage'),
					meta: { noAuth: true, title: 'contacts' },
				},
				{
					path: '/rules',
					name: 'Rules',
					component: () => import('@/pages/Main/RulesPage'),
					meta: { noAuth: true, title: 'rules' },
				},
				{
					path: '/safety',
					name: 'Safety',
					component: () => import('@/pages/Main/SafetyPage'),
					meta: { noAuth: true, title: 'faq' },
				},
				{
					path: '/cookie',
					name: 'Cookie',
					component: () => import('@/pages/Main/CookiePage'),
					meta: { noAuth: true, title: 'faq' },
				},
				{
					path: '/privacy',
					name: 'Privacy',
					component: () => import('@/pages/Main/PrivacyPage'),
					meta: { noAuth: true, title: 'faq' },
				},
			],
		},
		{
			path: '/login',
			name: 'Login',
			component: () => import('@/pages/Main/LoginPage'),
			meta: { noAuth: true, title: 'login' },
		},
		{
			path: '/register',
			name: 'Register',
			component: () => import('@/pages/Main/RegisterPage'),
			meta: { noAuth: true, title: 'sign_up' },
		},

		// registration steps
		{
			path: '/registration-steps',
			name: 'RegistrationSteps',
			component: () => import('@/pages/Main/RegistrationSteps'),
			meta: { noAuth: true, title: 'sign_up' },
		},

		// personal account
		{
			path: '/personal-account',
			name: 'PersonalAccount',
			component: () => import('@/layouts/PersonalAccount'),
			redirect: { name: 'ProfilePage' },
			children: [
				{
					path: '/profile',
					name: 'ProfilePage',
					component: () => import('@/pages/PersonalAccount/Profile/ProfilePage'),
					meta: { title: 'profile' },
				},
				{
					path: '/users',
					name: 'UsersPage',
					component: () => import('@/pages/PersonalAccount/Users/UsersPage'),
					meta: { title: 'users' },
				},
				{
					path: '/users/:id',
					name: 'UserInfo',
					component: () => import('@/pages/PersonalAccount/Users/UserInfo'),
					meta: { title: 'users' },
				},
				{
					path: '/company-data',
					name: 'CompanyDataChangeSteps',
					component: () => import('@/pages/PersonalAccount/Profile/CompanyDataChangeSteps'),
					meta: { title: 'change-data' },
				},
				{
					path: '/tariffs',
					name: 'TariffsPage',
					component: () => import('@/pages/PersonalAccount/Tariffs/TariffsPage'),
					meta: { title: 'tariffs' },
				},
				{
					path: '/whatsapp-reports',
					name: 'WhatsappReports',
					component: () => import('@/pages/PersonalAccount/Reports/WhatsappReports'),
				},
				{
					path: '/whatsapp-report',
					name: 'WhatsappReport',
					component: () => import('@/pages/PersonalAccount/Reports/WhatsappReport'),
				},
				{
					path: '/email-reports',
					name: 'EmailReports',
					component: () => import('@/pages/PersonalAccount/Reports/EmailReports.vue'),
					meta: { title: 'email-reports' },
				},
				{
					path: '/email-report/:id',
					name: 'EmailReport',
					component: () => import('@/pages/PersonalAccount/Reports/EmailReport.vue'),
					meta: { title: 'email-reports' },
				},
				{
					path: '/sms-reports',
					name: 'SmsReports',
					component: () => import('@/pages/PersonalAccount/Reports/SmsReports.vue'),
					meta: { title: 'sms-reports' },
				},
				{
					path: '/sms-report/:id',
					name: 'SmsReport',
					component: () => import('@/pages/PersonalAccount/Reports/SmsReport.vue'),
					meta: { title: 'sms-reports' },
				},
				{
					path: '/contact-books-emails',
					name: 'ContactBooksEmails',
					component: () => import('@/pages/PersonalAccount/ContactBooks/Email/ContactBooksEmails.vue'),
					meta: { title: 'books-email' },
				},
				{
					path: '/contact-books-sms',
					name: 'ContactBooksSms',
					component: () => import('@/pages/PersonalAccount/ContactBooks/Sms/ContactBooksSms.vue'),
					meta: { title: 'books-sms' },
				},
				{
					path: '/contact-book-email/:id',
					name: 'ContactBookEmailPage',
					component: () => import('@/pages/PersonalAccount/ContactBooks/Email/ContactBookEmailPage.vue'),
					meta: { title: 'books-email' },
				},
				{
					path: '/contact-book-sms/:id',
					name: 'ContactBookSmsPage',
					component: () => import('@/pages/PersonalAccount/ContactBooks/Sms/ContactBookSmsPage.vue'),
					meta: { title: 'books-sms' },
				},
				{
					path: '/contact-books-phone-calling',
					name: 'ContactBooksPhoneCalling',
					component: () =>
						import('@/pages/PersonalAccount/ContactBooks/PhoneCalling/ContactBooksPhoneCalling.vue'),
					meta: { title: 'books-phone-call' },
				},
				{
					path: '/contact-book-phone-calling/:id',
					name: 'ContactBookPhoneCalling',
					component: () =>
						import('@/pages/PersonalAccount/ContactBooks/PhoneCalling/ContactBookPhoneCalling.vue'),
					meta: { title: 'books-phone-call' },
				},
				{
					path: '/viber-mailings',
					name: 'ViberMailingsPage',
					component: () => import('@/pages/PersonalAccount/Mailings/Viber/ViberMailingsPage'),
				},
				{
					path: '/viber-mailing/:id',
					name: 'ViberMailing',
					component: () => import('@/pages/PersonalAccount/Mailings/Viber/ViberMailing'),
				},
				{
					path: '/viber-mailing/create',
					name: 'CreateViberMailing',
					component: () => import('@/pages/PersonalAccount/Mailings/Viber/CreateViberMailing.vue'),
				},
				// {
				// 	path: '/whatsapp-mailing/edit/:id',
				// 	name: 'EditWhatsappMail',
				// 	component: () => import('@/pages/PersonalAccount/Mailings/Viber/EditWhatsappMail.vue'),
				// },
				{
					path: '/create-mailing',
					name: 'NewBot',
					component: () => import('@/pages/PersonalAccount/Mailings/Viber/NewBotPage'),
				},
				{
					path: '/email-mailing',
					name: 'EmailMailing',
					component: () => import('@/pages/PersonalAccount/Mailings/Email/EmailMailingPage'),
					meta: { title: 'mailings-email' },
				},
				{
					path: '/email-mailing/:id',
					name: 'WatchEmail',
					component: () => import('@/pages/PersonalAccount/Mailings/Email/WatchEmail'),
					meta: { title: 'watch-mailing' },
				},
				{
					path: '/email-edit/:id',
					name: 'EditEmail',
					component: () => import('@/pages/PersonalAccount/Mailings/Email/EditEmail'),
					meta: { title: 'edit-mailing' },
				},
				{
					path: '/create-email',
					name: 'NewEmail',
					component: () => import('@/pages/PersonalAccount/Mailings/Email/NewEmailPage'),
					meta: { title: 'create-mailing' },
				},
				{
					path: '/create-email/:id',
					name: 'NewEmailWithTemplate',
					component: () => import('@/pages/PersonalAccount/Mailings/Email/NewEmailPage'),
					meta: { title: 'create-mailing' },
				},
				{
					path: '/sms-mailings',
					name: 'SmsMailings',
					component: () => import('@/pages/PersonalAccount/Mailings/SMS/SmsMailings'),
					meta: { title: 'mailings-sms' },
				},
				{
					path: '/create-sms-mailing',
					name: 'CreateSmsMailing',
					component: () => import('@/pages/PersonalAccount/Mailings/SMS/CreateSmsMailing'),
					meta: { title: 'create-mailing' },
				},
				{
					path: '/sms-mailing-page/:id',
					name: 'SmsMailingPage',
					component: () => import('@/pages/PersonalAccount/Mailings/SMS/SmsMailingPage'),
					meta: { title: 'watch-mailing' },
				},
				{
					path: '/sms-edit/:id',
					name: 'EditSmsMailing',
					component: () => import('@/pages/PersonalAccount/Mailings/SMS/EditSmsMailing'),
					meta: { title: 'edit-mailing' },
				},
				{
					path: '/phone-callings',
					name: 'PhoneCallings',
					component: () => import('@/pages/PersonalAccount/Mailings/PhoneCallings/PhoneCallings.vue'),
					meta: { title: 'phone-callings' },
				},
				{
					path: '/phone-calling/:id',
					name: 'PhoneCalling',
					component: () => import('@/pages/PersonalAccount/Mailings/PhoneCallings/PhoneCalling.vue'),
					meta: { title: 'watch-mailing' },
				},
				{
					path: '/create-phone-calling',
					name: 'CreatePhoneCalling',
					component: () => import('@/pages/PersonalAccount/Mailings/PhoneCallings/CreatePhoneCalling.vue'),
					meta: { title: 'create-mailing' },
				},
				{
					path: '/edit-phone-calling/:id',
					name: 'EditPhoneCalling',
					component: () => import('@/pages/PersonalAccount/Mailings/PhoneCallings/EditPhoneCalling.vue'),
					meta: { title: 'edit-mailing' },
				},
				{
					name: 'EmailTemplates',
					path: '/templates/email',
					component: () => import('@/pages/PersonalAccount/Templates/EmailTemplates'),
					meta: { title: 'templates' },
				},
				{
					path: '/personal-smtp-accounts',
					name: 'PersonalSMTPAccounts',
					component: () => import('@/pages/PersonalAccount/SMTP/SMTPAccounts'),
					meta: { title: 'personal-smtp-accounts' },
				},
				{
					path: '/create-personal-smtp',
					name: 'CreatePersonalSMTP',
					component: () => import('@/pages/PersonalAccount/SMTP/CreateSMTP'),
					meta: { title: 'create-personal-smtp-account' },
				},
				{
					path: '/personal-smtp-accounts/:id',
					name: 'EditPersonalSMTP',
					component: () => import('@/pages/PersonalAccount/SMTP/EditSMTP'),
					meta: { title: 'edit-personal-smtp-account' },
				},
				{
					path: '/personal-tele2-accounts',
					name: 'PersonalTele2Accounts',
					component: () => import('@/pages/PersonalAccount/PersonalSMSTele2/PersonalTele2Accounts'),
					meta: { title: 'personal-accounts-tele2' },
				},
				{
					path: '/create-personal-tele2-account',
					name: 'CreatePersonalTele2Account',
					component: () => import('@/pages/PersonalAccount/PersonalSMSTele2/CreatePersonalTele2Account'),
					meta: { title: 'create-personal-account-tele2' },
				},
				{
					path: '/personal-tele2-accounts/:id',
					name: 'EditPersonalTele2Account',
					component: () => import('@/pages/PersonalAccount/PersonalSMSTele2/EditPersonalTele2Account'),
					meta: { title: 'edit-personal-account-tele2' },
				},
				{
					path: '/personal-tele2-accounts',
					name: 'PersonalTele2Accounts',
					component: () => import('@/pages/PersonalAccount/PersonalSMSTele2/PersonalTele2Accounts'),
					meta: { title: 'personal-accounts-tele2' },
				},
				{
					path: '/create-personal-tele2-account',
					name: 'CreatePersonalTele2Account',
					component: () => import('@/pages/PersonalAccount/PersonalSMSTele2/CreatePersonalTele2Account'),
					meta: { title: 'create-personal-account-tele2' },
				},
				{
					path: '/personal-tele2-accounts/:id',
					name: 'EditPersonalTele2Account',
					component: () => import('@/pages/PersonalAccount/PersonalSMSTele2/EditPersonalTele2Account'),
					meta: { title: 'edit-personal-account-tele2' },
				},
			],
		},

		// admin account
		{
			path: '/admin',
			name: 'Admin',
			component: () => import('@/layouts/AdminLayout'),
			redirect: { name: 'AdminUsers' },
			children: [
				{
					path: '/organizations',
					name: 'AdminUsers',
					component: () => import('@/pages/Admin/Organizations/AdminUsers'),
					meta: { admin: true, title: 'organizations' },
				},
				{
					path: '/payment-requests',
					name: 'PaymentRequests',
					component: () => import('@/pages/Admin/Requests/PaymentRequests'),
					meta: { admin: true, title: 'requests' },
				},
				{
					path: '/payment-requests/request',
					name: 'PaymentRequest',
					component: () => import('@/pages/Admin/Requests/PaymentRequest'),
					meta: { admin: true, title: 'requests' },
				},
				{
					path: '/registration-requests',
					name: 'RegistrationRequests',
					component: () => import('@/pages/Admin/Requests/RegistrationRequests'),
					meta: { admin: true, title: 'requests' },
				},
				{
					path: '/registration-requests/:id',
					name: 'RegistrationRequest',
					component: () => import('@/pages/Admin/Requests/RegistrationRequest'),
					meta: { admin: true, title: 'requests' },
				},
				{
					path: '/company-data-requests',
					name: 'DataChangeRequests',
					component: () => import('@/pages/Admin/Requests/DataChangeRequests'),
					meta: { admin: true, title: 'requests' },
				},
				{
					path: '/company-data-requests/:id',
					name: 'DataChangeRequest',
					component: () => import('@/pages/Admin/Requests/DataChangeRequest'),
					meta: { admin: true, title: 'requests' },
				},
				{
					path: '/change-payment-requests',
					name: 'ChangePaymentDataRequests',
					component: () => import('@/pages/Admin/Requests/ChangePaymentDataRequests'),
					meta: { admin: true, title: 'requests' },
				},
				{
					path: '/change-payment-requests/:id',
					name: 'ChangePaymentDataRequest',
					component: () => import('@/pages/Admin/Requests/ChangePaymentDataRequest'),
					meta: { admin: true, title: 'requests' },
				},
				{
					path: '/feedback-requests',
					name: 'FeedbackRequests',
					component: () => import('@/pages/Admin/Requests/FeedbackRequests'),
					meta: { admin: true, title: 'requests' },
				},
				{
					path: '/appeals',
					name: 'AppealsPage',
					component: () => import('@/pages/Admin/Appeals/AppealsPage'),
					meta: { admin: true, title: 'appeals' },
				},
				{
					path: '/appeal/:id',
					name: 'AppealItem',
					component: () => import('@/pages/Admin/Appeals/AppealItem'),
					meta: { admin: true, title: 'appeals' },
				},
				{
					path: '/appeal-chat/:id',
					name: 'AppealChat',
					component: () => import('@/pages/Admin/Appeals/AppealChat'),
					meta: { admin: true, title: 'appeals' },
				},
				{
					path: '/services',
					name: 'ServicesPage',
					component: () => import('@/pages/Admin/Services/ServicesPage'),
					meta: { admin: true, title: 'services' },
				},
				{
					path: '/services/:id',
					name: 'Service',
					component: () => import('@/pages/Admin/Services/ServicePage'),
					meta: { admin: true, title: 'services' },
				},
				{
					path: '/admin-contacts',
					name: 'AdminContacts',
					component: () => import('@/pages/Admin/AdminContacts'),
					meta: { admin: true, title: 'contacts' },
				},
				{
					path: '/terms',
					name: 'TermsPage',
					component: () => import('@/pages/Admin/TermsPage'),
					meta: { admin: true, title: 'rules' },
				},
				{
					path: '/admin-tariffs',
					name: 'TariffsAdmin',
					component: () => import('@/pages/Admin/Tariffs/TariffsAdmin'),
					meta: { admin: true, title: 'tariffs' },
				},
				{
					path: '/admin-edit-tariff/:category/:id',
					name: 'EditTariffAdmin',
					component: () => import('@/pages/Admin/Tariffs/EditTariffAdmin'),
					meta: { admin: true, title: 'edit-tariff' },
				},
				{
					path: '/admin-create-tariff',
					name: 'CreateTariffAdmin',
					component: () => import('@/pages/Admin/Tariffs/CreateTariffAdmin'),
					meta: { admin: true, title: 'create-tariff' },
				},
				{
					path: '/email-templates',
					name: 'EmailTemplatesAdmin',
					component: () => import('@/pages/Admin/Templates/Email/EmailTemplatesAdmin'),
					meta: { admin: true, title: 'templates' },
				},
				{
					path: '/smtp-accounts',
					name: 'SMTPAccounts',
					component: () => import('@/pages/Admin/SMTP/SMTPAccounts'),
					meta: { admin: true, title: 'accounts-smtp' },
				},
				{
					path: '/create-smtp',
					name: 'CreateSMTP',
					component: () => import('@/pages/Admin/SMTP/CreateSMTP'),
					meta: { admin: true, title: 'create-account' },
				},
				{
					path: '/smtp/:id',
					name: 'EditSMTP',
					component: () => import('@/pages/Admin/SMTP/EditSMTP'),
					meta: { admin: true, title: 'edit-account' },
				},
				{
					path: '/whatsapp-accounts',
					name: 'WhatsappAccounts',
					component: () => import('@/pages/Admin/Whatsapp/WhatsappAccounts'),
					meta: { admin: true },
				},
				{
					path: '/create-whatsapp-account',
					name: 'CreateWhatsappAccount',
					component: () => import('@/pages/Admin/Whatsapp/CreateWhatsappAccount'),
					meta: { admin: true },
				},
				{
					path: '/whatsapp-account/:id',
					name: 'EditWhatsappAccount',
					component: () => import('@/pages/Admin/Whatsapp/EditWhatsappAccount'),
					meta: { admin: true },
				},
				{
					path: '/tele2-accounts',
					name: 'Tele2Accounts',
					component: () => import('@/pages/Admin/SMSTele2/Tele2Accounts'),
					meta: { admin: true, title: 'accounts-tele2' },
				},
				{
					path: '/create-tele2-account',
					name: 'CreateTele2Account',
					component: () => import('@/pages/Admin/SMSTele2/CreateTele2Account'),
					meta: { admin: true, title: 'create-account-tele2' },
				},
				{
					path: '/tele2-accounts/:id',
					name: 'EditTele2Account',
					component: () => import('@/pages/Admin/SMSTele2/EditTele2Account'),
					meta: { admin: true, title: 'edit-account-tele2' },
				},
			],
		},

		// Tools layout
		{
			path: '/tools',
			name: 'Tools',
			component: () => import('@/layouts/ToolsLayout'),
			redirect: { name: 'Chat' },
			children: [
				{
					path: '/chat',
					name: 'Chat',
					component: () => import('@/pages/Tools/Admin/ChatPage'),
				},
				{
					path: '/create-email-template',
					name: 'CreateEmailTemplateAdmin',
					component: () => import('@/pages/Tools/Admin/CreateEmailTemplateAdmin'),
					meta: { admin: true, title: 'create-template' },
				},
				{
					path: '/templates/email/create',
					name: 'CreateEmailTemplate',
					component: () => import('@/pages/Tools/CreateEmailTemplate'),
					meta: { title: 'create-template' },
				},
				{
					path: '/edit-email-template/:id',
					name: 'EditEmailTemplateAdmin',
					component: () => import('@/pages/Tools/Admin/EditEmailTemplateAdmin'),
					meta: { admin: true, title: 'edit-template' },
				},
				{
					path: '/templates/email/edit/:id',
					name: 'EditEmailTemplate',
					component: () => import('@/pages/Tools/EditEmailTemplate'),
					meta: { title: 'edit-template' },
				},
			],
		},
		{
			path: '/reset-password',
			name: 'ResetPassword',
			component: () => import('@/pages/Main/PasswordReset'),
			meta: { noAuth: true, title: 'reset-pass' },
			props: (route) => ({ token: route.query.token }),
		},
		{
			path: '/confirm-email',
			name: 'ConfirmEmail',
			component: () => import('@/pages/Main/ConfirmEmail'),
			meta: { noAuth: true, title: 'confirm' },
			props: (route) => ({ token: route.query.token }),
		},
		{
			path: '/:catchAll(.*)*',
			name: 'NotFound',
			component: () => import('@/pages/Main/NotFound'),
			meta: { noAuth: true, title: '404' },
		},
	],
});

routes.beforeEach(async (to, from, next) => {
	const token = localStorage.getItem('token');
	if (!token) {
		if (
			to.name === 'Login' ||
			to.name === 'Register' ||
			to.name === 'ResetPassword' ||
			to.name === 'Main' ||
			to.name === 'OurServices' ||
			to.name === 'OurPrices' ||
			to.name === 'ContactsPage' ||
			to.name === 'Rules' ||
			to.name === 'Safety' ||
			to.name === 'Cookie' ||
			to.name === 'Privacy'
		) {
			return next();
		}
		return next({ name: 'Login' });
	}

	if (token) {
		if (
			to.name === 'Main' ||
			to.name === 'OurServices' ||
			to.name === 'OurPrices' ||
			to.name === 'ContactsPage' ||
			to.name === 'Rules' ||
			to.name === 'Safety' ||
			to.name === 'Cookie' ||
			to.name === 'Privacy'
		) {
			return next();
		}
	}

	const userStore = useUserStore();
	await userStore.getUserData();
	if (!userStore.user.email_verified_at) {
		if (
			to.name === 'ConfirmEmail' ||
			to.name === 'Main' ||
			to.name === 'OurServices' ||
			to.name === 'OurPrices' ||
			to.name === 'ContactsPage' ||
			to.name === 'Rules' ||
			to.name === 'Safety' ||
			to.name === 'Cookie' ||
			to.name === 'Privacy'
		) {
			return next();
		}
		return next({ name: 'ConfirmEmail' });
	}

	const orderStore = useOrderStore();
	await orderStore.getOrder();
	if (!userStore.user?.confirm_organization_at && userStore.user.user_type !== 'admin') {
		if (to.name === 'RegistrationSteps') {
			return next();
		}
		return next({ name: 'RegistrationSteps' });
	}

	return next();
});

routes.beforeEach(async (to, from, next) => {
	const token = localStorage.getItem('token');
	const userStore = useUserStore();
	if (
		(to.name === 'Login' ||
			to.name === 'Register' ||
			(to.name === 'ConfirmEmail' && userStore.user?.email_verified_at) ||
			to.name === 'ResetPassword') &&
		token
	) {
		return next({ name: 'PersonalAccount' });
	} else {
		return next();
	}
});

routes.beforeEach((to, from, next) => {
	const userStore = useUserStore();
	if (!to.matched.some((page) => page.meta?.admin) && userStore.user?.user_type === 'admin') {
		return next({ path: '/admin' });
	} else if (to.matched.some((page) => page.meta?.admin) && userStore.user?.user_type !== 'admin') {
		return next({ path: '/profile' });
	} else {
		return next();
	}
});

// routes.beforeResolve((to, from, next) => {
// 	if (to.name === 'CreateEmailTemplate' || to.name === 'EditEmailTemplate') {
// 		if (
// 			/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
// 				navigator.userAgent
// 			)
// 		) {
// 			next({ name: 'EmailTemplates' });
// 		} else {
// 			next();
// 		}
// 	} else {
// 		next();
// 	}
// });

// routes.beforeResolve((to, from, next) => {
// 	if (to.name === 'CreateEmailTemplateAdmin' || to.name === 'EditEmailTemplateAdmin') {
// 		if (
// 			/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
// 				navigator.userAgent
// 			)
// 		) {
// 			next({ name: 'EmailTemplatesAdmin' });
// 		} else {
// 			next();
// 		}
// 	} else {
// 		next();
// 	}
// });

routes.beforeEach((to) => {
	document.title = to.meta.title ? `Calldom | ${i18next.t('meta.' + to.meta.title)}` : 'Calldom';
	document.head.querySelector('meta[name="description"]').content = i18next.t('meta.description');
});

export default routes;
